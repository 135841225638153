import React from 'react'
import './buttonsecondary.css';

export default function ButtonSecondary({ children }) {
    return (
        <div className='button-secondary'>
            <button>{children}</button>
        </div>
    )
}
