import React from 'react'
import LogoImg from '../img/logo hero.png';
import './logo.css';

export default function Logo() {
    return (
        <div className='logo'>
            <img src={LogoImg} alt="777slotsbay logo" />
        </div>
    )
}
