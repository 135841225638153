import React from 'react'
import './section.css';

export default function Section({ children, bg = 'light' }) {

    const style = {
        backgroundColor: bg === 'light' ? 'transparent' : '#eaeaea',
    }

    return (
        <section className='section' style={style}>
            {children}
        </section>
    )
}
