import React from 'react'
import './buttonprimary.css';

export default function ButtonPrimary({ children }) {
    return (
        <div className='button-primary'>
            <button>
                {children}
            </button>
        </div>
    )
}
