import React from 'react'
import './button3.css';

export default function Button3({children}) {
    return (
        <button className='button3'>
            {children}
        </button>
    )
}
