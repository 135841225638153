import React from 'react'
import './header.css';
import { ButtonPrimary, Title, Logo } from './index';

export default function Header({ children }) {
    return (
        <div className='header'>
            <Logo />
            <Title>{children}</Title>
            <a href="http://affiliates.777slotsbay-affiliate.com/" taget='_blank'><ButtonPrimary>  Join now</ButtonPrimary></a>
        </div>
    )
}