import React from 'react'
import './button4.css';

export default function Button4({children}) {
    return (
        <button className='button4'>
            {children}
        </button>
    )
}
